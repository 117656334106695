import { SignupSuccess } from '../../../ui-components';
import { useCallback, useMemo } from 'react';
import {useDispatch} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { resetState } from '../../../actions/action';
import useMediaQuery from '../../../utils/matchMedia/matchMedia';

function Success({email, password}) {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const navigateToSignIn = useCallback(() => {
        dispatch(resetState());
        navigate('/signin', {state: {email: email, password: password}});
    }, [navigate, dispatch, email, password]);

    const isLargeScreen = useMediaQuery("(min-width: 768px)");
    
    const signupSuccessOverrides = useMemo(() => {
        return {
           
            SignupSuccess: {
                height: "100vh",
                width: isLargeScreen ? "50vw" : "100vw",
                padding: isLargeScreen ? "64px 60px 64px 60px" : "20px"
            },

            AppLogo: {
                display: isLargeScreen ? "flex" : "none"
            },

            "Signup Successful!": {
                children: "Sign Up Successful!",
                width: "unset"
            },

            "Email confirmation text": {
                width: "unset"
            },

             "Your account is created and you can now sign in below.": {
                width: "unset"
             },

            "Button": {
                onClick: navigateToSignIn,
                children: "Sign In"
            },

            "email": email,
            "password": password
        };

    }, [navigateToSignIn, email, password, isLargeScreen]);
    
    return (
        <SignupSuccess overrides={signupSuccessOverrides} ></SignupSuccess>
    )
};

export default Success;

