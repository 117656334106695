import SignupLeftSection from '../../../ui-components/SignupLeftSection';
import "./GetDiscovered.css";
import useMediaQuery from '../../../utils/matchMedia/matchMedia';

const GetDiscovered = () => {
    const isLargeScreen = useMediaQuery("(min-width: 768px)");
    const signupLeftSectionOverrides = {
        
        SignupLeftSection: {
            height: "unset",
            width: isLargeScreen ? "50vw" : "100vw",
        },
        IntroLayoutDarkMode: {
            height: "100%",
            width: isLargeScreen ? "50vw" : "100vw",
            padding: isLargeScreen ? "107px 60px 107px 60px" : "20px"
        },
        "Fill 90": {
            //boxSizing: "border-box",
            position: "absolute",
            right: "1.451px",
            bottom: "-33.79px",
            width: "221.632px",
            height: "49.631px",
            transform: "rotate(6deg)",
            viewBox: {
                minX: "0",
                minY: "0",
                width: "233.632px",
                height: "39.631px"
            },
            fill: "#CCA43B",
            stroke: "black"
        },

         "Custom Resume and Cover Letter Downloads": {
            shrink: "1",
            width: "100%",
         },
         "Free Personal Assessments": {
            shrink: "1",
            width: "100%",
         },
         "Life style coaching tool": {
            shrink: "1",
            width: "100%",
         },

         "Customized Alternative Job Recommendations": {
            shrink: "1",
            width: "100%",
         },
         "Personalized Job Matching": {
            shrink: "1",
            width: "100%",
         },
         "Want to use your skills and personal strengths in a job where you are challenged and more engaged? Sign up for our unique candidate discovery process. Let us help you discover where you can do your best work.": {
            width: "100%",
            shrink: "1",
         }


    };

    return (
        <SignupLeftSection overrides={signupLeftSectionOverrides} />
    )
}

export default GetDiscovered;